import {Outlet} from '@remix-run/react';
import logoVaerdict from '~/assets/vaerdict-colored-logo.svg';
export default function AuthLayout() {
  return (
    <div className="flex flex-col sm:flex-row items-stretch min-h-screen">
      <div className="sm:flex-1 bg-primary flex-col gap-12 text-white flex justify-center items-center">
        <div className="size-60 sm:size-80 object-contain bg-white p-10 mt-12 sm:mt-0 rounded-full">
          <img src={logoVaerdict} alt="logo" className="object-contain " />
        </div>
        <h1 className="text-2xl sm:text-4xl font-bold text-center mb-12 sm:mb-0">Bienvenue sur Vaerdict.fr</h1>
      </div>
      <div className="sm:flex-1">
        <Outlet />
      </div>
    </div>
  );
}
